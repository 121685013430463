import React, { useRef } from "react";
import TrelloCard from "../components/TrelloCard";

const DriverManagement = () => {

    const lanes = useRef({
        REGISTERED: {
            route: "/adm/driver/list?status=REGISTERED&page_size=9999999999",
            title: "Em cadastro",
        },
        IN_VALIDATION: {
            route: "/adm/driver/list?status=IN_VALIDATION&page_size=9999999999",
            title: "Em validação",
        },
        ENABLED: {
            route: "/adm/driver/list?status=ENABLED&page_size=9999999999",
            title: "Habilitados",
        },
        EXPIRED: {
            route: "/adm/driver/list?status=EXPIRED&page_size=9999999999",
            title: "Expirados",
        },
    });

    return <TrelloCard lanes={lanes} />;
};

export default DriverManagement;
